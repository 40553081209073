import { environment } from '../../../environments/environment';
declare function require(name: string);
const productRouteConfig = require(`../../../assets/${environment.client_folder}/json/product-config.json`);
const sba = productRouteConfig['sbaloans']['route']
  ? productRouteConfig['sbaloans']['route'] + '/'
  : '';
const nonsba = productRouteConfig['businessloans']['route']
  ? productRouteConfig['businessloans']['route'] + '/'
  : '';
const common = productRouteConfig['common']['route']
  ? productRouteConfig['common']['route'] + '/'
  : '';

const UNRESOLVED_ROUTES = {
  invalid_session_redirect: ``,
};
// application-info/loan-details

const COMMON_ROUTES = {
  'manage-leads': `/${common}manage-leads`,
  signin: `/${common}login`,
  dashboard: `/${common}welcome`,
  'reset-password': `/${common}reset-password`,
  'banker-dashboard': `/${common}dashboard`,
  'banker_app_info': `/${common}manage-loan/application`,
  'banker_app_documents': `/${common}manage-loan/documents`,
  'certificates_letters': `/${common}manage-loan/documents/certificates-letters`,
  'banker_app_underwriting': `/${common}manage-loan/underwriting`,
  'banker_app_notes': `/${common}manage-loan/notes`,
  'banker_app_activities': `/${common}manage-loan/activity`,
  'banker_app_inbox':`/${common}manage-loan/inbox`,
  'banker_connect_to_customer': `/${common}manage-loan/connect-customer`,
  'backend-loan-details': `/${common}manage-loan/application/loan/details`,
  'backend-business-details': `/${common}manage-loan/application/business/details`,
  'backend-borrower-info': `/${common}manage-loan/application/borrower/details`,
  'backend-owner-details': `/${common}manage-loan/application/manage-owners/owner-details`,
  'backend-collateral-details': `/${common}manage-loan/application/manage-collaterals/collateral-details`,
  'backend-affiliate-details': `/${common}manage-loan/application/manage-affiliates/affiliate-details`,
  'backend-fund-info':`/${common}manage-loan/application/funding/information`,
  'backend-pfs': `/${common}manage-loan/application/manage-financial-information/pfs`,
  'summary-note-by-rm': `/${common}manage-loan/application/summary-note`,
  'banker_sba_main_nav': `/${common}manage-loan/sba`,
  'banker_sba': `/${common}manage-loan/sba/form-1919`,
  'banker_sba_1920_table': `/${common}manage-loan/sba/form-1920`,
  'banker_sba_1920': `/${common}manage-loan/sba/form-1920/form1920`,
  'banker_sba_1050_table': `/${common}manage-loan/sba/form-1050`,
  'banker_sba_1050': `/${common}manage-loan/sba/form-1050/form1050`,
  'banker_sba_landlord_table': `/${common}manage-loan/sba/form-landlord`,
  'banker_sba_landlord': `/${common}manage-loan/sba/form-landlord/formlandlord`,
  'banker_sba_601_table': `/${common}manage-loan/sba/form-601`,
  'banker_sba_601': `/${common}manage-loan/sba/form-601/form601`,
  'banker_sba_159_table': `/${common}manage-loan/sba/form-159`,
  'banker_sba_159': `/${common}manage-loan/sba/form-159/form159`,
  'banker_sba_submission': `/${common}manage-loan/sba/sba/sba-submission`,
  'banker_sba_submission_activity': `/${common}manage-loan/sba/sba/sba-activity`,
  'banker_sba_submission_details': `/${common}manage-loan/sba/sba/sba-details`,
  'underwriting-summary': `/${common}manage-loan/underwriting/summary`,
  'business-individual-verification': `/${common}manage-loan/underwriting/business-individual-verification`,
  'underwriting-credit-report': `/${common}manage-loan/underwriting/credit-report/`,
  // 'underwriting-exp': `/${common}manage-loan/underwriting/credit-report/experian`,
  // 'underwriting-irs': `/${common}manage-loan/underwriting/irs`,
  'underwriting-bss': `/${common}manage-loan/underwriting/bank-statement`,
  cashflow: `/${common}manage-loan/underwriting/cashflow`,
  'business-financial': `/${common}manage-loan/cashflow/cashflow-analysis/business-financial`,
  'personal-financial': `/${common}manage-loan/cashflow/cashflow-analysis/personal-financial`,
  'add-backs': `/${common}manage-loan/cashflow/cashflow-analysis/add-backs`,
  gdscr: `/${common}manage-loan/cashflow/cashflow-analysis/gdscr`,
  // 'underwriting-dnb': `/${common}manage-loan/underwriting/fraud_risk`,
  'underwriting-evaluations': `/${common}manage-loan/underwriting/evaluations`,
  'hmda': `/${common}manage-loan/underwriting/evaluations/hmda`,
  'collateral-reportable': `/${common}manage-loan/underwriting/evaluations/collateral-reportable`,
  'collateral-eval': `/${common}manage-loan/underwriting/evaluations/collateral-evaluation`,
  'underwriting-taxguard': `/${common}manage-loan/underwriting/taxguard`,
  'credit-memo' : `/${common}manage-loan/underwriting/credit-memo`,
  'cam-borrower-info' : `/${common}manage-loan/credit-memo/credit-approval-form/borrower-information`,
  'cam-rate-info' : `/${common}manage-loan/credit-memo/credit-approval-form/rate-information`,
  'cam-collateral-info' : `/${common}manage-loan/credit-memo/credit-approval-form/collateral-information`,
  'cam-project-overview' : `/${common}manage-loan/credit-memo/credit-approval-form/project-overview`,
  'cam-additional-info' : `/${common}manage-loan/credit-memo/credit-approval-form/additional-information`,
  'cam-cashflow-review' :  `/${common}manage-loan/credit-memo/credit-approval-form/cashflow-review`,
  'cam-personal-financial-information' :  `/${common}manage-loan/credit-memo/credit-approval-form/personal-financial-information`,
  'cam-background-searches' : `/${common}manage-loan/credit-memo/credit-approval-form/background-searches`,
  'cam-decision-history' : `/${common}manage-loan/decision/decision-history`,
  'letter_navigation': `/${common}manage-loan/letter`,
  'etran-addl-info': `/${common}manage-loan/underwriting/additional-info`,
  // 'credit-memo-form' : `/${common}manage-loan/credit-memo-form`
};
const decline_naics=[];

const SBA_ROUTES = {
  'gross-sales': `/${sba}business-details`,
  'about-business': `/${sba}business-details/about-business`,
  'owner-details': `/${sba}owner-details`,
  'another-owner-detail': `/${sba}owner-details/business-info`,
  'consent-received': `/${sba}owner/verification/consent-received`,
  'financial-information': `/${sba}owner-details/financial-info`,
  'borrower-information': `/${sba}owner-details/borrower-info`,
  'affiliate-information': `/${sba}owner-details/affiliate-info`,
  'term-sheet': `/${sba}owner-details/term-sheet`,
  'sba-form': `/${sba}sba-form`,
  'loan-type': `/${sba}loan-request`,
  'sba-documents': `/${sba}documents`,
  'sba-form-1': `/${sba}sba-form/sba-form-1`,
  'sba-form-2': `/${sba}sba-form/sba-form-2`,
  'sba-form-3': `/${sba}sba-form/sba-form-3`,
  'sba-form-4': `/${sba}sba-form/sba-form-4`,
  'sba-form-5': `/${sba}sba-form/sba-form-5`,
  'sba-form-6': `/${sba}sba-form/sba-form-6`,
  'sba-form-7': `/${sba}sba-form/sba-form-7`,
  'sba-form-8': `/${sba}sba-form/sba-form-8`,
  // 'sba-form-complete': `../${sba}sba-form-complete`,
  'owner-documents': `../${sba}documents/additional`,
  'document-complete': `../${sba}doc-complete`,
  'owner-documents-business': `../${sba}documents/additional`,
  'document-complete-business': `../${sba}documents/doc-complete`,
  'application-submit': `../${sba}application-submit`,
  'sba-primary': `/${sba}sba-form/sba-primary`,
  'collateral-details': `/${sba}collateral-details`,
  'collateral-details-form': `/${sba}collateral-details/form`,
  'funding-information': `/${sba}funding-information/account-info`,
  'application-complete': `/${sba}thank-you`,
  finish: `/${sba}thank-you/completed`,
  'pfs-form': `/${sba}pfs-form`,
  'pfs-form-additonal': `../../pfs-form/additional-pfs`,
  'document-additonal': `../documents/additional`,
  'document-additional-link': `../../documents-pfs/additional`,
  'doc-pfs-thanks': `../../thank-you/doc-pfs-thanks`,
  'pfs-thanks': '../thank-you/pfs-thanks',
  'document-complete-hash': `../../thank-you/doc-thanks`,
  'pfs-individual-complete': `../../thank-you/doc-pfs-thanks`,
  'doc-thanks': '../../thank-you/doc-thanks',
  'connect-banker':`/${sba}connect-banker`
};

const NON_SBA_ROUTES = {};

const PATH_RESOLVER = {
  [sba]: {},
  [nonsba]: {},
};

export const SBA_CONSTANTS = {
  PATH_RESOLVER: {
    ...PATH_RESOLVER,
  },
  FOOTER_CONGIF: {
    footer_text: "",
  },
  CLIENT_CONFIG: {
    // title: environment.title,
    name: environment.client_name,
     project_name: environment.project_name,
    // addressl: environment.client_addressl,
    // city: environment.client_city,
    // state: environment.client_state,
    // country: environment.client_country,
    // zipcode: environment.client_zipcode,
    // clientNumber: environment.client_number,
    // client_url: environment.client_url,
    // privacy_url: environment.privacy,
    // siteMap_url: environment.siteMap,
    // terms_url: environment.terms,
    // no_reply_mail: environment.noreplymail,
    // emailId: environment.client_email,
  },

  APP_ROUTES: {
    ...UNRESOLVED_ROUTES,
    ...COMMON_ROUTES,
    ...SBA_ROUTES,
    ...NON_SBA_ROUTES,
  },

  APP_STEP: {
    'loan-type': 1,
    'create-profile': 1,
    'about-business': 2,
    'borrower-information': 3,
    'owner-details': 4,
    'another-owner-detail': 4,
    all_owners_detail: 4,
    'financial-information': 4,
    'affiliate-information': 4,
    'term-sheet': 4,
    'collateral-details': 5,
    'sba-primary': 6,
    'sba-form': 6,
    'sba-form-1': 6,
    'sba-form-2': 6,
    'sba-form-3': 6,
    'sba-form-4': 6,
    'sba-form-5': 6,
    'sba-form-6': 6,
    'sba-form-7': 6,
    'sba-form-8': 6,
    'sba-documents': 7,
    'funding-information': 8,
    'ifs-data': 8,
    'pfs-form': 8,
  },

  SLUG: {    
    'manage_leads':'manage_leads',
    'manage_leads_filter': 'manage_leads_filter',
    'get-users-details' : 'get-users-details',
    'sba_process_type': 'sba_process_type',
    'update_lead_status': 'update_lead_status',
    'add_notes': 'add_notes',
    'loan-applications' : 'dashboard',
    'start_an_application': 'start_an_application',
    configuration: 'configuration',
    signin: 'signin',
    get_application_details: 'get_application_details',
    get_owner_reference: 'get_owner_reference',
    gdscr: 'gdscr',
    business_financial: 'business_financial',
    cash_flow_common_fields: 'cash_flow_common_fields',
    personal_financial: 'personal_financial',
    add_backs: 'add_backs',
    personal_financial_pdf: 'personal_financial_pdf',
    business_financial_pdf: 'business_financial_pdf',
    'banker_signin':'banker_signin',
    'forget-password':'forget-password',
    'reset-password':'reset-password',
    'connect_banker_customer':'connect_banker_customer',
    'underwriter_accordion_info_exp':'underwriter_accordion_info_exp',
    'underwriter_accordion_info_tu':'underwriter_accordion_info_tu',
    'manage_loans_filter': 'manage_loans_filter',
    'manage_loans': 'manage_loans',
    'application-current-stage': 'application-current-stage',
    "backend_dashboard": "backend-dashboard",
    'backend-dashboard-filter-form': 'backend-dashboard-filter-form',
    "application_detail": "application_detail",
    "activity_logs": "activity_logs",
    'requested_documents': 'requested-documents',
    'update_requested_docs': 'update_requested_docs',
    'document_widget_data': 'document-widget-data',
    'get_backend_users': 'get_backend_users',
    'download_all': 'download_all',
    "total-exposure-value": "total-exposure-value",
    "cam-borrower-info":"cam-borrower-info",
    'cam-proj-overview':'cam-proj-overview',
    'bank_statement_summary': 'bank_statement_summary',
    'about-business': 'about-business',
    'about-business-banker':'about-business-banker',
    "app_detail_optimized": "app_detail_optimized",
    'pfs-form': 'pfs-form',
    'get_stages_for_role':'get_stages_for_role',
    'checklist':'checklist',
    account_payable: 'account_payable',
    schedule_cash: 'schedule_cash',
    contingent_income: 'contingent_income',
    installment_account: 'installment_account',
    life_insurance: 'life_insurance',
    loan_against_life_insurance: "loan_against_life_insurance",
    notes_payable: 'notes_payable',
    other_liabilities: 'other_liabilities',
    other_personal_property_assets: 'other_personal_property_assets',
    real_estate_owned: 'real_estate_owned',
    source_of_income: 'source_of_income',
    spouse_details: 'spouse_details',
    stocks_and_bonds: 'stocks_and_bonds',
    unpaid_taxes: 'unpaid_taxes',
    form_1919: 'form_1919',
    'collateral-details': 'collateral-details',
    resend_form_1919: 'resend_form_1919',
    esign_sba1919: 'esign_sba1919',
    'pfs-pdf': 'pfs-pdf',
    'request-pfs-banker': 'request-pfs-banker',
    'additional_information': 'additional_information',
    'summary_note': 'summary_note',
    'get-owners-data': 'get-owners-data',
    'owner-guarantor-banker-table' : 'owner-guarantor-banker-table',
    'sibling_owner_form' : 'sibling_owner_form',
    'request_owners_consent' : 'request_owners_consent',
    'add-edit-affiliate-info' : 'add-edit-affiliate-info',
    'sbaforms': 'sbaforms',
    'update_sbaform':'update_sbaform',
    'get_eval_hmda': 'get_eval_hmda',
    'evaluation_cra': 'evaluation_cra',
    'download_analyis_pdf': 'download_analyis_pdf',
    'underwriter_accordion_info': 'underwriter_accordion_info',
    'hmda': 'hmda',
    'collateral_evaluation_analysis': 'collateral_evaluation_analysis',
    'aba_valuation_from': 'aba-valuation-form',
    'aba_from': 'aba-form',
    'collateral_extra_info_form': 'collateral-extra-info-form',
    'real_estate_valuation_form': 'real-estate-valuation-form',
    'real_estate_form': 'real-estate-form',
    'collateral_evaluation_masters': 'collateral_evaluation_masters',
    'update_checklist': 'update_checklist',
    'submit_checklist': 'submit_checklist',
    'esign-sba-pfs': 'esign-sba-pfs',
    'get_notes':'get_notes',
    'business-businessRef': 'business-businessRef',
    'banker-by-ids': 'banker-by-ids',
    'taxguard-order-status': 'taxguard-order-status',
    'taxguard-order-owner-status': 'taxguard-order-owner-status',
    'send-taxguard-form-mail': 'send-taxguard-form-mail',
    'place-taxguard-order': 'place-taxguard-order',
    'place-taxguard-owner-order': 'place-taxguard-owner-order',
    'generate-taxguard-form': 'generate-taxguard-form',
    'cam-rate-information' : `cam-rate-information`,
    'credit-memo-pfs': 'credit-memo-pfs',
    'cam_collateral':'cam_collateral',
    'cam-background-searches' : 'cam-background-searches',
    'cam_addn_info':'cam_addn_info',
    'cam_cashflow_review_forms' : 'cam_cashflow_review_forms',
    'assign_to': 'assign-to',
    'get-stages-actions': 'get-stages-actions',
    'get_caf_decision_history' : 'get_caf_decision_history',
    'loan-type': 'loan-type',
    'ui-component': 'ui-component',
    all_owners_detail: 'all_owners_detail',
    add_child_owners: 'add_child_owners',
    'owner-details': 'owner-details',
    'another-owner-detail': 'another-owner-detail',
    'financial-information': 'financial-information',
    'borrower-information': 'borrower-information',
    'affiliate-information': 'affiliate-information',
    'term-sheet': 'term-sheet',
    'delete-owner': 'delete-owner',
    'term-sheet-decline': 'term-sheet-decline',
    'term-sheet-mail': 'term-sheet-mail',
    'sba-primary': 'sba-primary',
    'sba-form-1': 'sba-form-1',
    'sba-form-2': 'sba-form-2',
    'sba-form-3': 'sba-form-3',
    'sba-form-4': 'sba-form-4',
    'sba-form-5': 'sba-form-5',
    'sba-form-6': 'sba-form-6',
    'sba-form-8': 'sba-form-8',
    'sba-form-7': 'sba-form-7',
    post_form_1919: 'post_form_1919',
    'get-business-data': 'get-business-data',
    'funding-information': 'funding-information',
    'role-stage-mapping': 'role-stage-mapping',
    'lead-assignment': 'lead-assignment',
    'etran-addl-info': 'etran-addl-info',
    'loan-actions-data': 'loan-actions-data',
    'generate-taxguard-indi-form': 'generate-taxguard-indi-form',
    'retrieve-order-status': 'retrieve-order-status',
    verify_owner_hash: 'verify_owner_hash',
    'verify-owner-consent': 'verify-owner-consent',
    'verify-owner-consent-primary':'verify-owner-consent-primary',
    'get-affiliates': 'get-affiliates',
    'delete-affiliates':'delete-affiliates',
    'add_edit_affiliate_info_v2':'add-edit-affiliate-info-v2',
    'sibling_affiliate_form':'sibling_affiliate_form',
  },

  MAIL_TEMPLATE: {
     copyright_text: environment.copyright_text,
     senders_name: environment.mail_client_name,
     project_name: environment.project_name,
     no_reply_mail: environment.noreplymail,
  },

  PER_PAGE_RECORDS: [
    {"value": 10 },
    {"value": 25 },
    {"value": 50 },
    {"value": 100 }
  ],

  FILTER_TYPE_MANAGE_LOANS: [
      {
          id: "5c20bf7e27105c78ad6f9281",
          order: 1,
          parent_id: null,
          product_type: null,
          status: 1,
          type: "loan_id",
          value: "Loan ID"
      },
      {
          id: "5c20bf7e27105c78ad5f9281",
          order: 2,
          parent_id: null,
          product_type: null,
          status: 1,
          type: "business_name",
          value: "Business Name"
      }
  ],

  APPLICATION_STATUS: {
    'application_in_progress': '5c20bf7e27105c78ad7f9289',
    'application_soft_decline': '60bdfb51c0a3494024772c0d',
    'application_hard_decline' : '60bdfd521b7b0c7268b744ab',
    'app_completed': '5c20bf7e27105c78ad7f9288',
    'app_soft_withdrawn' : '60d22bf23c05824838f8fe6b',
    'app_hard_withdrawn' : '60f1597ad61fcdcfe4eed5c2',
    'app_submitted': '5c20bf7e27105c78ad7f9281',
    'app_underwiter': '60cf935635f4bc612456ac8c',
    'loan_officer': '5c20bf7e27105c78ad7f9282',
    'app_approved': '60d99408a833186f78b5540f',
    'sent_to_capital_stream': '61ae6614cac781e241be38fe',
    'sent_to_capital_stream_under_350k': '61e45f2517de1a170cade6fc',
    'pending_additional_info': '61af209341c74fb68c34db5e',
    'approved_but_not_accepted': '6209548f79fa7f0bddc6127e',
    'send_to_capital_stream_failed': '6209549a403846e283d44d7d'
  },

  FILTER_TYPE_MANAGE_LEADS: [
    {
        id: "5c20bf7e27105c78ad4f9281",
        order: 1,
        parent_id: null,
        product_type: null,
        status: 1,
        type: "email_address",
        value: "Email Address"
    },
    {
        id: "5c20bf7e27105c78ad3f9282",
        order: 2,
        parent_id: null,
        product_type: null,
        status: 1,
        type: "phone",
        value: "Phone"
    }
  ],

  BANKER_APP_DETAILS_MAIN_NAVS: [
    { label: "Application", route: "banker_app_info" },
    { label: "Documents", route: "banker_app_documents" },
    { label: "Underwriting", route: "banker_app_underwriting" },
    // { label: "Decision", route: "banker_app_decision" },
    { label: "SBA", route: "banker_sba_main_nav" },
    { label: "Notes", route: "banker_app_notes" },
    { label: "Activities", route: "banker_app_activities" },
    { label: "Inbox", route: "banker_app_inbox" },
    // { label: "Connect to Customer", route: "banker_connect_to_customer" }
  ],

  BANKER_APP_INFO_SIDE_NAVS: [
    { label : "Loan Detail", route : 'backend-loan-details', css : { span : "ld-icon" } },
    { label : "Business Details", route : "backend-business-details", css : { span : "bd-icon" } },
    // { label : "Borrower Information", route : "backend-borrower-info", css : { span : "bd-icon" } },
    { label : "Owner/Guarantor", route : 'backend-owner-details', css : { span : "og-icon" } },
    { label : "Affiliate Details", route : "backend-affiliate-details", css : { span : "af-icon" } },
    { label : "Collateral Details", route : "backend-collateral-details", css : { span : "cd-icon" } },
    // { label : "Funding Information", route : "backend-fund-info", css : { span : "pfs-icon" } },
    { label : "Financial Statement", route : "backend-pfs", css : { span : "pfs-icon" } },
    { label : "Initial Summary Note", route : 'summary-note-by-rm', css : { span : "ai-icon" } },
  ],
  BANKER_APP_SBA_SIDE_NAVS: [
    { label : "Form 1919", route : 'banker_sba', css : { span : "ld-icon" } },
    { label : "Form 1920", route : 'banker_sba_1920_table', css : { span : "ld-icon" } },
    { label : "Form 1050", route : 'banker_sba_1050_table', css : { span : "ld-icon" } },
    { label : "Landlord Waiver", route : 'banker_sba_landlord_table', css : { span : "ld-icon" } },
    { label : "Form 601", route : 'banker_sba_601_table', css : { span : "ld-icon" } },
    { label : "Form 159", route : 'banker_sba_159_table', css : { span : "ld-icon" } },
    { label : "SBA Submission", route : 'banker_sba_submission', css : { span : "ld-icon" } },
    { label : "SBA Submission Activity", route : 'banker_sba_submission_activity', css : { span : "ld-icon" } },
    { label : "SBA Details", route : 'banker_sba_submission_details', css : { span : "ld-icon" } }
  ],

  UNDERWRITING_SIDE_NAVS: [
    // { label : "Summary", route : 'underwriting-summary', css : { span : "" } },
    { label : "Business/Individual Verification", route : "business-individual-verification", css : { span : "" } },
    { label : "Credit Report", route : 'underwriting-credit-report', css : { span : "" } },
    // { label : "Credit Report Transunion", route : 'underwriting-tu', css : { span : "" } },
    // { label : "Credit Report Experian", route : 'underwriting-exp', css : { span : "" } },
    { label : "Taxguard", route : "underwriting-taxguard", css : { span : "" } },
    { label : "Bank Statement Summary", route : "underwriting-bss", css : { span : "" } },
    { label : "Cash Flow Analysis", route : "cashflow", css : { span : "" } },
    { label : "Evaluations", route : "underwriting-evaluations", css : { span : "" } },
    { label : "Credit Memo", route : "credit-memo", css : { span : "" } },
    { label : "Additional Information", route : "etran-addl-info", css : { span : "" } }
  ],

  CAM_CAF_NAVS : [
    { label : "Back", route : 'credit-memo', css : { span : "" } },
    { label : "Borrower Information", route : 'cam-borrower-info', css : { span : "" } },
    { label : "Rate Information", route : 'cam-rate-info', css : { span : "" } },
    { label : "Collateral Information", route : 'cam-collateral-info', css : { span : "" } },
    { label : "Project Overview", route : 'cam-project-overview', css : { span : "" } },
    { label : "Additional Information", route : 'cam-additional-info', css : { span : "" } },
    { label : "Cash Flow Review", route : 'cam-cashflow-review', css : { span : "" } },
    { label : "Personal Financial Information(PFS)", route : 'cam-personal-financial-information', css : { span : "" } },
    { label : "Background Searches", route : 'cam-background-searches', css : { span : "" } },
  ],

  YES_NO: {
    YES: '5e8723158f2f4e3ac475fab6',
    NO: '5e8723158f2f4e3ac475fab7'
  },

  // SMS_TEMPLATE: {
  //   ISD_CODE_FOR_SMS_TRIGGER: environment.isd_code_for_sms_trigger,
  //   SMS_BODY_TEXT: environment.sms_body_text,
  //   SMS_BODY_TEXT_MITEK:"Hello, Thanks for choosing  for your finance needs. Please complete your ID verification on your phone,",
  //   SMS_GENERAL_TEXT: environment.sms_general_text,
  //   SMS_APPLICATION_SUBMIT:`Thank you for choosing  for your financial needs. Your application is being reviewed by one of our bankers. For more information on your loan application, please log onto "${environment.customerJourneyUrl}"`,
  //   SMS_CUSTOMER_CONNECT:`Thank you for choosing  for your financial needs. Your recent query has been responded to by  one of our bankers. Please log onto "${environment.customerJourneyUrl}" to view the same`,
  //   SMS_CREATE_PROFILE:'Thank you for starting your loan application with . Your profile has been confirmed.',
  //   SMS_SEND_CONSENT:'Thank you for choosing  for your financial needs. We are missing some details on your loan application. Kindly check your email for more information on the requirement.'
  // },

  TAXGUARD_BUSINESS_STRUCTURE: [
    "Corporation",
    "Partnership",
    "Limited Liability Company",
    "Limited Partnership",
    "PLLC",
    "Profession Corporation",
    "Sole Proprietorship",
    // "Individual" // only for individual owner
  ],

  CHECKLIST_DOCUMENT_MESSAGE: {
    checklist_error: 'Please mark the status of the checklist',
    document_error: 'Please mark the status of the document list',
    checklist_document_error: 'Please mark the status of checklist and document list'
  },

  ECOA_DAYS: 30,

  DATE_FORMAT: {
    default_format: 'MM-DD-YYYY',
    pipe_date_format: 'MM-dd-YYYY',
    pipe_time_format: 'h:mm a'
  },

  gender:{
    male:'M',
    female:'F',
    not_disclosed:'X'
  },
  veteran:{
    non_veteran: '1',
    veteran: '2',
    service_disabled_veteran: '3',
    spouse_of_veteran:'4',
    not_disclosed:'X'
  },
  race: {
    american_indian_or_alaska_native: '1',
    asian:'2',
    black_or_african_american:'3',
    native_hawaiian_or_pacific_islander:'4',
    white:'5',
    not_disclosed:'X'
  },
  ethnicity:{
    hispanic_or_latino: 'H',
    not_hispanic_or_latino: 'N',
    not_disclosed:'X'
  }
};
