const COORELATION_NAME_PREFIX = "CPA-";

export const CUSTOMER_PORTAL = {
    login: 'signin',
    signup: 'signup',
    prequal: 'prequal',
    offer: 'offer',
    loginMobile: 'signin-mobile',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    changePassword: 'change-password',
    otp: 'otp-verification',
    resendOtp: 'resend-otp',
    loginOtp: 'signin-otp-verification',
    lendingNeeds: 'lending-needs',
    loanDetails: 'loan-details',
    productinfo: 'product-info',
    businessDetails: 'business-details',
    financeDetails: 'business-financial',
    emailVerify: 'verify-email',
    userVerify: 'verify-user',
    collateral: 'collateral',
    profile: 'user-profile',
    bankStatementUpload: 'manual-upload-bank-statements',
    documentDownload: 'download-documents',
    documentDelete: 'delete-documents',
    connectBankDelete: 'delete-connect-bank',
    netBanking: 'yodlee',
    logout: 'logout',
    applicantDetails: 'applicant-details',
    dashboard: 'dashboard',
    affiliate_login: 'ppp2-aicpa-login',
    affiliate_dashboard: 'aicpa-dashboard',
    affiliate_create_user: 'cpabusiness-aicpa-create-user',
    affiliate_create_user2: 'cpabusiness-aicpa-create-user-client',
    // affiliate_create_user: 'aicpa-create-user',
    ppp2_create_user: 'ppp2-aicpa-create-user',
    ppp2_create_user2: 'ppp2-aicpa-create-user-client',
    ppp2_create_application_client: 'ppp2-aicpa-create-application-client',
    affiliate_create_backend_user: 'aicpa-create-affiliate-user',
    affiliate_user_verification: 'ppp2-aicpa-user-verification',
    affiliate_user_check_token: 'aicpa-user-verification-check-hash',
    affiliate_user_verify_token: 'aicpa-user-verify-hash',
    ppp2_edit_user: 'ppp2-edit-user',
    edit_client_action: 'edit-client-action',
    get_urjanet:'get-urjanet',
    save_urjanet_ref:'urjanet-ref',
    download_urjanet_docs:'download-urjanet-doc',
    cpabusiness_registration_mail: 'cpabusiness_registration_mail',
    opt_in_premium_support: 'opt-in-premium-support',
    business_structure: 'term-loan-business-structure',
    owner_details:'term-loan-owners-details',
    term_loan_details: 'term-loan-details',
    term_loan_business_info: 'term-loan-business-info',
    document_management: 'term-loan-document-management',
    owner_consent_mail_term_loan : 'owner_consent_mail_term_loan',
    term_loan_owner_hash: 'term-loan-owner-hash',
    owner_consent_config: 'owner_consent_config',
    expire_hash: 'expire_hash',
    get_owners: 'get_owners',
    industry_search: 'industry_search',
    pre_qualification: 'pre_qualification',
    pre_qual_before_application: 'pre_qual_before_app',
    yodlee_cred_check: 'check_yodlee_creds',
    tl_document_listing: 'document-listing-term-loan',
    wc_business_structure: 'wc-business-structure',
    wc_owner_details:'wc-owners-details',
    wc_loan_details: 'wc-loan-details',
    wc_business_info: 'wc-business-info',
    wc_document_management: 'wc-document-management',
    wc_owner_hash: 'wc-owner-hash',
    wc_pre_qualification: 'wc-pre-qualification',
    wc_new_app: 'working_capital_new_app',
    wc_document_listing: 'document-listing-working-capital',
    ertc_loan_inquiry: 'ertc-loan-inquiry',
    inquiry_form: 'inquiry-form',
    ertc_new_app: 'ertc_new_app',
    ertc_pre_qualification: 'ertc-pre-qualification',
    ertc_business_structure: 'ertc-business-structure',
    ertc_business_info: 'ertc-business-info',
    ertc_loan_details: 'ertc-loan-details',
    ertc_owner_details:'ertc-owners-details',
    ertc_document_management: 'ertc-document-management',
    
    /**sba journey*/
    sba_loan_request: "loan-type",
    sba_new_app:'sba_new_app',
    application_view_sba: 'application-view-sba',
    sba_loan_inquiry : 'sba-loan-inquiry',
    wc_loan_inquiry: 'wc-loan-inquiry',
    update_welcome_popup_flag:'update_welcome_popup_flag',
    acl_permission_list:'acl-permission-list',
    cpabusiness_acl_permission_create: 'cpabusiness-acl-permission-create',
    cpabusiness_acl_new_role: 'cpabusiness-acl-new-role'
}

export const VERIFIER_PORTAL = {
    verifier_login: 'verifier-login',
    mfa_verification: 'mfa-verification',
    MASTER_DATA_CALL: 'master-data-verifier-portal',
    NON_VERIFIED_LIST: 'verifier-cpa-list-non-verified',
    VERIFIED_LIST: 'verifier-cpa-list',
    DOWNLOAD_DOCUMENTS: 'download-documents',
    CPA_REFERENCES: 'get-cpa-references',
    CPA_CLEAR_SEARCH: 'cpa-clear-search',
    CPA_CLEAR_PULL: 'cpa-clear-quick-pull',
    HTML_TO_PDF: 'html-to-pdf',
    CLEAR_REPORT_UPLOAD: 'clear-report-upload',
    VERIFIER_CHECKLIST: 'verifier-checklist',
    CHANGE_CPA_STATUS:  'change-cpa-status',
    CPA_VERIFY_SUCCESS_MAIL: 'verifier_verification_mail',
    REVIEWER_NOTES: 'reviewer-notes',
    CPA_ASSIGN_TO: 'cpa-assign-to',
    VERIFIER_ACTIVITY: 'verifier_activity',
    CPA_FIRM_BLOCK_UNBLOCK: 'cpa-firm-block-unblock',
    VERIFIER_DECLINE_MAIL: 'verifier_decline_mail',
    VERIFIER_DIRECT_CUSTOMER_ASSIGN_DECLINE_MAIL: 'direct_customer_assign_decline_mail',
    DELETE_APPLICATION: 'delete_backend_user',
    LOGIN_DECLINE_MAIL: 'decline_mail',
    edit_cpa_details: 'edit_cpa_details',
    sba_activation_email: 'send_sba7a_activation_email',
    GET_ALL_USER_PLANS : 'get_all_user_plans'
}
export const OWNER_CONSENT = {
    PENDING: 'pending',
    RECEIVED: 'received'
}

export const getCorelationID = (loanNO,env,appId) => COORELATION_NAME_PREFIX + env + '-'+ loanNO + appId ;
